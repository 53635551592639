/**global.css***/
// media query css import
@import './mobilemedia';
//

$titlecolor: #697f94;
$darkgraycolor: #002f6c;
$colororange: #fe5000;
$graycolor: #f7f7f7;
$fieldslabel: #9b9b9b;
$errormessage: #ff0707;
$bluebutton: #1f8bb9;
$darknavycolor: #002f6c;

/******/

/******number arrows removed*******/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s !important;
}

/******scrollbar Css****/
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c6cbd0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $colororange;
}

/**img responsive**/
.img-fluid,
.modal-dialog.cascading-modal.modal-avatar .modal-header,
.video-fluid {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
}
/******/

/*******input cross mark remvoe*****/
/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

/******end******/

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/***********/
html {
  height: 100%;
  overflow-y: scroll;
}

body {
  font-family: 'Raleway', sans-serif;
  background-color: #fafafa;
  min-height: 100%;
  position: relative;
  overflow-y: initial !important;
}

.txt_orange {
  color: $colororange;
}

.txt_navy {
  a {
    color: $darknavycolor !important;
  }
}

.txt_grey {
  color: #b5bcc2 !important;
}

.background_grey {
  background-color: #b5bcc2 !important;
}

.noEvents {
  opacity: 0.6;
  pointer-events: none !important;
}

/*** white wrapper***/
.whitebg-wrapper {
  background-color: #fff;

  @include desktop {
    padding: 0 15px;
  }
}

.edit-wrapper {
  width: 100%;
  padding: 2rem;

  @include tablet {
    padding: 2rem 0;
  }
}
/*********/

/***notification***/
.success-notification {
  font: 600 16px/19px Lato;
  color: #20a533;
  width: 100%;
  text-align: center;
  position: relative;
  top: 40px;
}
/*****/

/****icon****/
.success_pr_icon {
  background: url(/images/icons/Successfulicon.svg) no-repeat 0 0;
  width: 16px;
  height: 12px;
  display: inline-block;
  margin-right: 5px;
  background-size: contain;
}

.search_icon {
  background: url(/images/icons/search_icons/searchorange.svg) no-repeat 0 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
}
.sortby {
  background: url(/images/icons/search_icons/sortby.svg) no-repeat 0 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
}
.shape {
  background: url(/images/icons/search_icons/shape.svg) no-repeat 0 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
}
/*******/

/***close popup text****/
.close_auth_popup {
  font: 500 14px/18px 'Raleway';
  color: $colororange;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
/*********/

/******radio button******/
.form-check-input[type='radio']:not(:checked) + label:before,
.form-check-input[type='radio']:not(:checked) + label:after,
label.btn input[type='radio']:not(:checked) + label:before,
label.btn input[type='radio']:not(:checked) + label:after,
.form-check-input[type='radio']:checked + label:after,
.form-check-input[type='radio'].with-gap:checked + label:before,
.form-check-input[type='radio'].with-gap:checked + label:after,
label.btn input[type='radio']:checked + label:after,
label.btn input[type='radio'].with-gap:checked + label:before,
label.btn input[type='radio'].with-gap:checked + label:after {
  border: 2px solid $colororange;
}
.form-check-input[type='radio']:checked + label:after,
.form-check-input[type='radio'].with-gap:checked + label:after,
label.btn input[type='radio']:checked + label:after,
label.btn input[type='radio'].with-gap:checked + label:after {
  background: $colororange;
}

/*****end*****/

/****dropdwon select commoncss*****/
.dropdown-content li > a,
.dropdown-content li > span {
  color: $darkgraycolor;
  font: 500 12px/18px 'Lato';
}
.dropdown-content li:hover,
.dropdown-content li.active {
  background: rgba(242, 126, 68, 0.1);

  .dropdown-content li > a,
  .dropdown-content li > span {
    font-weight: 600;
  }
}

/*******/

/*******input focus****/
.md-form input[type='search']:not(.browser-default):focus:not([readonly]) {
  border-bottom: 1px solid $colororange;
  box-shadow: inherit;
}

/*******/

/****buttons****/
.fill-orange-btn,
.fill-blue-btn {
  background-color: $colororange;
  font: 600 16px/18px 'Raleway';
  text-align: center;
  letter-spacing: 0.32px;
  color: #fff;
  border-radius: 2px;
  padding: 12px 40px;
  border: 0.08rem solid transparent;
  margin-right: 15px;
}

.fill-blue-btn {
  background: $bluebutton;
  font: 600 16px/18px 'Raleway';
  text-align: center;
  letter-spacing: 0.32px;
  color: #ffffff;
  padding: 12px;
  margin: 0;
}

.outline-btn {
  border: 0.08rem solid $colororange;
  font: 600 16px/18px 'Raleway';
  text-align: center;
  letter-spacing: 0.32px;
  color: $colororange;
  padding: 25px;
  border-radius: 2px;
  padding: 12px 40px;
  background: transparent;

  &:hover {
    background: $colororange;
    color: #fff;
  }
}

/***********/

/****margins***/
.mt-6 {
  margin-top: 5rem;
}
.my-6 {
  margin: 5.5rem 0;

  @include tablet {
    margin: 2.5rem 0;
  }
}

/**headings***/
.heading-h5 {
  font-size: 20px;
  color: $darkgraycolor;
  font-weight: 500;
  font-family: 'Raleway';
  display: inline-block;
}
.heading_h2 {
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: $darkgraycolor;
}
.heading_h4 {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: $darkgraycolor;

  @include tablet {
    display: inline-block;
  }
}

/*********/

/****popup css***/
.add_account_popup,
.add_product_popup {
  &.modal-dialog {
    max-width: 795px;
  }
  .modal-content {
    background: #fafafa;
  }
  .account_name_list ul li .city_name {
    width: 165px;

    @include smallphone {
      width: 45%;
    }
  }
  .product_name_list ul li .product_number {
    width: 110px;

    @include smallphone {
      width: 45%;
    }
  }
  .add-min-height {
    min-height: 600px;
  }
  .modal-header {
    border: none;
  }
  .close_auth_popup {
    opacity: 1;
    z-index: 999;
  }

  .filter-panel .filter-list-wrap {
    width: 462px;
    left: -300px;

    @include phone {
      width: 324px;
      left: -235px;
      right: 0 !important;
    }
  }
  .mobile-block {
    display: inline-block;

    @include smallphone {
      display: block;
      margin: 10px 0;
      text-align: center;
    }
  }
}
/*****/

/**avatar Photo**/
.avatar-photo {
  // background: url(../images/current_use_avatar.png) no-repeat 0;
  width: 35px;
  height: 35px;
  display: inline-block;

  &.card_prfl_pic {
    background: #ffffff;
    width: 97px;
    height: 97px;
    position: relative;
    margin-top: -24px;
    padding: 10px;
    overflow: hidden;

    &.shadows {
      box-shadow: -2px -3px 5px -1px rgba(0, 0, 0, 0.1),
        4px -10px 2px -10px rgba(0, 0, 0, 0.1);
    }
  }
}

.edit-avatar-photo {
  background: url(/images/icons/Profilecircle.svg) no-repeat 0 top;
  width: 116px;
  height: 116px;
  display: inline-block;
  background-size: cover;
  border-radius: 100%;
}

.prfl-pic-center {
  text-align: left;

  @include tablet {
    text-align: center;
  }
}
.edit-profile-pic {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 118px;

  .hovertooltip {
    .input-tooltip {
      top: -42px;

      .triangle {
        left: -211px;
      }
    }
  }

  .avatar-photo.card_prfl_pic {
    margin-top: 5px;
    padding: 0;
  }

  .remove_photo {
    background: $colororange;
    color: #fff;
    border-radius: 15px;
    font-family: Raleway;
    font-weight: 500;
    font-size: 12px;
    line-height: 8px;
    border: 0;
    padding: 6px 15px;
  }
}

.edit_btn_transparent {
  position: absolute;
  top: 10px;
  background: rgba($darkgraycolor, 0.7);
  color: #fff;
  z-index: 1;
  border: 0;
  border-radius: 11px;
  padding: 2px 16px;
  font-size: 13px;
  font-family: Lato;
  left: 31px;
}

/*****/
.hovertooltip {
  position: relative;

  .triangle {
    position: absolute;
    margin: auto;
    top: 38px;
    left: 0px;
    right: 0;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    background: #fff;
    z-index: -1;
  }

  .input-tooltip {
    position: absolute;
    background-color: #fff;
    border: 1px solid #898989;
    padding: 5px 10px;
    font-size: 13px;
    font-family: Lato;
    top: -49px;
    width: 268px;
    color: #000;
    z-index: 1;
    line-height: 19px;
    visibility: hidden;
    opacity: 0;
    max-width: 350px;
    /* width: 100%; */
    font-weight: 400;

    span {
      color: $colororange;
      text-decoration: underline;
    }
  }

  &:hover {
    .input-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

/***/

/******form-fields******/
.input-field {
  background-color: $graycolor;
  border: 1px solid #d7dbdf;
  color: $fieldslabel;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  padding: 5px;
  margin: 8px 0 23px;
  padding: 17px 8px 13px;
  height: 52px;
  position: relative;

  &.error-border {
    border: 1px solid #ff0000;
  }

  &.fixed-field {
    padding: 8px 8px 7px;

    .form-control {
      top: 0;
    }
  }

  &.url-field {
    padding: 8px 8px 7px;

    .form-control {
      margin: 0;
      top: 0;
      border: 1px solid #ff0000 !important;
    }

    .input-group-append {
      position: absolute;
      bottom: 25px;
      right: 0;
    }

    .fill-orange-btn {
      padding: 0px 10px;
      margin-right: 0px;
      font-size: 12px;
    }
  }

  &.arrow-list {
    background: #f7f7f7 url(/images/icons/Dropdownarrow.svg) no-repeat 97%
      center;
    cursor: pointer;
  }

  .field_label {
    position: relative;
    top: -4px;
    margin-bottom: 2px;
  }

  label {
    font-weight: 700;
  }

  .md-form {
    margin: 0;

    label {
      transform: translateY(1px);
      transform-origin: 0% 100%;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;

      @include phone {
        font-size: 12px;
      }

      &.active {
        transform: translateY(-17px) scale(0.8);
        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #9b9b9b !important;

        @include phone {
          font-size: 12px;
        }
      }
    }
  }

  &:hover {
    .input-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  .form-control {
    padding: 0;
    margin: 0px 0 0 5px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #4a4a4a;
    background-color: transparent;
    border: 0;
    height: auto;
    width: 95%;
    position: relative;
    top: 4px;

    &:focus {
      border: transparent;
      outline: 0;
      box-shadow: none;
    }

    @include tablet {
      width: 89%;
    }
  }

  .md-form
    > input[type]:-webkit-autofill:not(.browser-default):not([type='search'])
    + label {
    transform: translateY(-17px) scale(0.8);
    font-size: 14px;
    line-height: 14px;
    transform-origin: 0 0;
    position: absolute;
    top: 5.5px;
  }

  .md-form input:not([type]),
  .md-form input[type='text']:not(.browser-default),
  .md-form input[type='email']:not(.browser-default),
  .md-form input[type='password']:not(.browser-default),
  .md-form input[type='search']:not(.browser-default),
  .md-form input:not([type]):focus:not([readonly]),
  .md-form input[type='email']:not(.browser-default):focus:not([readonly]),
  .md-form input[type='search']:not(.browser-default):focus:not([readonly]),
  .md-form input[type='password']:not(.browser-default):focus:not([readonly]),
  .md-form input[type='text']:not(.browser-default):focus:not([readonly]) {
    border: 0;
    box-shadow: none;
  }

  .input-group-text {
    padding: 0px 0px;
    background: transparent;
    border: none;
  }

  &.textarea {
    resize: none;
    height: auto;
  }
}

@mixin error-msg {
  color: $errormessage;
  font-family: Lato;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.28px;
}

.error-message {
  @include error-msg;
  font-size: 10px;
  position: absolute;
  top: 100%;
  margin-top: 2px;

  span {
    background: url(/images/icons/Error.svg) no-repeat left 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    background-size: cover;
    vertical-align: top;
    margin-left: -9px;
  }
}

.error-message1 {
  @include error-msg;
  font-size: 10px;
}

.error-message2 {
  @include error-msg;
  font-size: 14px;
}

/*****end*****/

/*******end********/
/******Rep Card Prfl View****/
.acc-info-panel {
  background: #fff;
  padding: 6px 10px;
  height: 47.8%;

  &.pd-acc-height {
    height: 52%;
  }

  .txt_productcategories {
    margin-left: 0;
    position: relative;
    top: 5px;
  }
  .plan-info-txt {
    font: 600 14px/17px 'Lato';
    color: #4a4a4a;
  }
}
.txt_productcategories {
  color: #1f8bb9;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 14px;
}
.rep-profile-card-view {
  background-color: #ffffff;
  padding: 0px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 399px;
  position: relative;

  &.no-bg {
    background: none;
  }

  &.pdheightreduce {
    height: 381px;
  }

  .rep_dtl {
    padding: 0 12px;
  }

  .fill-orange-btn {
    margin-right: 0;
  }

  &.no-productcategory {
    height: 174px;
  }

  &.boxpanel {
    box-shadow: 0 0 7px -1px rgba(0, 0, 0, 0.2);
    margin-top: 2rem;
  }

  .avatar_avl_dtl {
    display: inline-block;
    vertical-align: top;
    margin-top: 0.8rem;
    width: 115px;
  }

  .card_rep_name {
    font-size: 14px;
    color: $darkgraycolor;
    font-weight: 400;
  }

  .card_prfl_name {
    color: $titlecolor;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }

  .account_email_icon {
    background: url(/images/icons/mail.svg) no-repeat left top;
    padding-left: 39px;
    background-size: 15%;
  }
  .account_pwd_icon {
    background: url(/images/icons/accountpwdicon.svg) no-repeat 3px top;
    padding-left: 39px;
    background-size: 14%;
  }
  .npi_icon {
    background: url(/images/icons/npiicon.svg) no-repeat 1px top;
    padding-left: 39px;
    background-size: 10%;
  }

  .card_prfl_designation {
    color: $darkgraycolor;
    font-size: 14px;
    font-weight: 500;
    max-width: 211px;
    display: inline-block;
    width: 100%;
  }

  .status_red {
    color: red;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    display: inline-block;
  }

  .status_green {
    color: #66bb6a;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    display: inline-block;
  }

  .rep_icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: top;

    &.title_icon {
      background: url(/images/icons/Title.svg) no-repeat 0;
      background-size: contain;
    }

    &.company_icon {
      background: url(/images/icons/Company.svg) no-repeat 0;
      background-size: contain;
    }

    &.mobile_icon {
      background: url(/images/icons/Mobile.svg) no-repeat 0;
      background-size: contain;
    }

    &.email_icon {
      background: url(/images/icons/Email.svg) no-repeat 0;
      background-size: contain;
    }

    &.calendar_icon {
      background: url(/images/icons/calendar.svg) no-repeat 0;
      background-size: contain;
    }
  }
}

/*****React Autocomplete******/
.react-autocomplete {
  display: block;

  .mdb-autocomplete-wrap {
    background: #f7f7f7;
    color: $darkgraycolor;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    position: relative;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.28px;
    margin-left: 0;
    padding: 0;
    width: 50%;
    top: 8px;
    height: 100%;
    overflow-y: scroll;
    max-height: 300px;

    @include tablet {
      width: 100%;
    }

    li {
      list-style-type: none;
      background: #ffffff;
      padding: 11px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }

  input {
    border: none;
    background: transparent;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #4a4a4a;

    &:focus {
      outline: none;
    }
  }
}

/******end****/

/****react Calender****/
.datepicker-label {
  .field_label {
    top: -13px;
  }
}

.react-datepicker-wrapper {
  display: block !important;
  position: relative;
  top: -11px;

  input {
    display: block;
    border: 0;
    background: transparent !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #4a4a4a;
    margin-left: 8px;

    &:focus {
      outline: none;
    }
  }
}

.datepicker-label.small_font {
  .field_label {
    font-size: 14px !important;
    color: '#757575' !important;
    font-weight: normal;
  }

  .react-datepicker-wrapper {
    display: block !important;
    position: relative;
    top: -11px;

    // input {
    //   font-size: 14px !important;
    //   color: '#757575';
    // }
  }
}

.react-datepicker {
  border: 0;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  background-color: #fafafa;
}

.react-datepicker__month-container {
  background: #fafafa;
}

.react-datepicker__header {
  border-bottom: 0;
  background-color: transparent;
  background-color: #fafafa !important;
  border-bottom: transparent !important;
}

.react-datepicker {
  font-family: 'Lato', sans-serif;
  color: rgba(0, 0, 0, 0.867782);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-weight: 400 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: $colororange !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2rem !important;
  line-height: 2rem !important;
}

.react-datepicker__month {
  margin: 0 8px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-family: 'Lato', sans-serif !important;
}

.react-datepicker__navigation--previous {
  left: 19px !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  background: url(/images/icons/CalendarArrow.svg) no-repeat 0 !important;
  border-right-color: inherit !important;
}

.react-datepicker__navigation--next {
  transform: rotate(-180deg);
}

.react-datepicker__navigation {
  width: 50px !important;
  height: 10px !important;
  border: 0 !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__navigation-icon--next {
  display: none;
}

.react-datepicker__navigation-icon--previous {
  display: none;
}

.react-autocomplete .mdb-autocomplete-wrap li {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.28px;
  color: #002f6c;
}

/****end*****/

/****toggle switch****/
.custom-control-label::before {
  background-color: #d8d9db;
  height: 1.2rem;
}

.custom-switch .custom-control-label::after {
  background-color: #fff;
  width: calc(1rem - 1px);
  height: calc(1rem - 2px);
}

.custom-switch .custom-control-label::before {
  border-radius: 1rem;
  width: 2rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #50d663;
  background-color: #50d663;
}

/***end***/

/*******Info Tooltip******/
.info-tooltip {
  position: absolute;
  font: 500 16px/19px 'Raleway';
  color: $darkgraycolor;
  background: #fafafa;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  padding: 7px;
  width: 129px;
  top: -35px;
  text-align: center;
  left: -47px;

  &.bottom {
    top: inherit;
    bottom: -37px;
  }
}

/******end****/

/*****product Categories popup******/
.multiple-product {
  position: absolute;
  width: 50%;

  @include tablet {
    width: 96%;
  }
}
.product-list {
  min-height: 27px;
  position: relative;
  top: -3px;
}
.product-wrap {
  background: #f7f7f7;
  color: $darkgraycolor;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: relative;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.28px;
  margin-left: 0;
  padding: 0;
  width: 50%;
  top: 8px;
  height: 100%;
  overflow-y: scroll;
  max-height: 500px;
  width: 100%;

  ul {
    padding: 5px;
    margin: 0;

    li {
      list-style-type: none;
      background: #ffffff;
      padding: 11px;
      margin-bottom: 5px;
      cursor: pointer;
      position: relative;
    }
  }

  .info_icon {
    right: 10px;
    top: 18px;
    position: absolute;

    .spciality_popover {
      display: none;
      top: -12px;
      right: 26px;
      left: inherit;
      width: 150px;

      &::after {
        transform: rotate(-136deg);
        top: 33px;
        left: 99%;
      }
    }

    &:hover {
      .spciality_popover {
        display: inline-block;
      }
    }
  }
}

/*****end****/

/*****checkbox****/
// [type="checkbox"]:not(:checked),
// [type="checkbox"]:checked {
//     position: absolute;
//     pointer-events: none;
//     opacity:0;
// }

.form-check {
  position: relative;
  display: block;
  padding-left: 0;
}

.form-check .form-control {
  display: none;
}

.form-check-input[type='checkbox'].filled-in:checked + label:after,
label.btn input[type='checkbox'].filled-in:checked + label:after {
  background-color: $colororange;
  border: 1px solid $colororange;
}

.form-check-input[type='checkbox'].filled-in:not(:checked) + label:after,
label.btn input[type='checkbox'].filled-in:not(:checked) + label:after {
  border: 1px solid $colororange;
}

// .form-check-input[type="checkbox"]+label,
// label.btn input[type="checkbox"]+label {
//     position: relative;
//     display: inline-block;
//     height: 1.5625rem;
//     padding-left: 31px;
//     line-height: 1.3rem;
//     cursor: pointer;
//     user-select: none;
//     font: 500 14px/22px "Lato";
//     letter-spacing: 0.28px;
//     color:$darkgraycolor;
// }

// .form-check-input[type="checkbox"].filled-in+label:before,
// .form-check-input[type="checkbox"].filled-in+label:after,
// label.btn input[type="checkbox"].filled-in+label:before,
// label.btn input[type="checkbox"].filled-in+label:after {
//     position: absolute;
//     left: 0;
//     z-index: 1;
//     content: "";
//     width: 20px;
//     height: 20px;
// }

// .form-check-input[type="checkbox"].filled-in:not(:checked)+label:before,
// label.btn input[type="checkbox"].filled-in:not(:checked)+label:before {
//     top: 0px;
//     /* left: 6px; */
//     width: 20px;
//     height: 20px;
//     /* -webkit-transform: rotateZ(37deg); */
//     /* transform: rotateZ(37deg); */
//     /* -webkit-transform-origin: 100% 100%; */
//     /* transform-origin: 100% 100%; */
//     /* width: 20px; */
//     border: .1em solid $colororange;
//     border-radius: 3px;
// }

// .form-check-input[type="checkbox"].filled-in+label:after
//   {
//     position: absolute;
//     left: 0;
//     z-index: 1;
//     content: "";
//     background:$colororange;
//     border-radius: 3px;
//     width: 20px;
//     height: 20px;

// }

// .form-check-input[type="checkbox"].filled-in:not(:checked)+label:after,
// label.btn input[type="checkbox"].filled-in:not(:checked)+label:after {
//     top: 0;
//     z-index: 0;
//     width: 20px;
//     height: 20px;
//     background-color: transparent;
// }
/**********/

/***Flip Icon****/
.flip-icon-panel {
  position: relative;
  top: 5%;

  .flip-icon {
    &:hover .info-tooltip {
      opacity: 1;
      visibility: visible;
      width: auto;
      white-space: nowrap;
      top: 36px;
      bottom: inherit;
      font-size: 10px;
      line-height: 12px;
      color: $darkgraycolor;
      left: -15px;
    }
  }
}
.flip-icon {
  position: absolute;
  right: 8%;
  top: 5%;
  width: 38px;
  height: 35px;
  background: url(/images/icons/flipiconlight.svg) no-repeat 0 0;
  background-size: contain;
  opacity: 1;
  cursor: pointer;

  &:hover .info-tooltip {
    opacity: 1;
    visibility: visible;
    width: auto;
    white-space: nowrap;
    top: 36px;
    bottom: inherit;
    font-size: 10px;
    line-height: 12px;
    color: #002f6c;
    left: -16px;
  }

  .info-tooltip {
    opacity: 0;
    visibility: visible;
    font-size: 10px;

    &.bottom {
      bottom: inherit;
      top: 35px;
      width: auto;
      left: -16px;
      white-space: nowrap;
    }
  }
}
/***End Flip Icon****/

.cursor {
  cursor: pointer !important;
}

/***custom Checkbox***/
.cust_checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-indent: 30px;
}

a {
  color: $colororange;

  &:hover {
    color: $colororange;
  }
}

/* Hide the browser's default checkbox */
.cust_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.cust_checkbox {
  position: absolute;
  top: 26px;
  left: 0;
  height: 19px;
  width: 19px;
  border: 1px solid $colororange;
  padding: 0;
  white-space: nowrap;
}

/* On mouse-over, add a grey background color */
.cust_checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.cust_checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.cust_checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.cust_checkbox .checkmark:after {
  left: 5px;
  top: 1px;
  width: 7px;
  height: 11px;
  border: solid $colororange;
  border-width: 0px 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/***/

/***favorite icon***/
.favorite_icon {
  background: url(/images/icons/search_icons/favouritestroke.svg) no-repeat 0px
    center;
  width: 22px;
  height: 21px;
  display: inline-block;
  background-size: contain;
  vertical-align: top;
}

.open-chat-icon {
  background: url(/images/icons/search_icons/chat2.svg) no-repeat 0px center;
  width: 29px;
  height: 21px;
  display: inline-block;
  background-size: contain;
  vertical-align: top;
}
/*********/

.signup_form_panel {
  .mdb-autocomplete-wrap {
    width: 100%;
  }
}

.preLogin {
  .fill-orange-btn {
    &:disabled {
      background: rgba(242, 126, 68, 0.5);
    }
  }
}

.password-icon {
  cursor: pointer;
  position: absolute !important;
  right: 11px;
  top: 17px;
  font-size: 18px;
  color: #9b9b9b;
}

/***filter buttons***/
.filter-btn {
  position: absolute;
  bottom: 0;
  margin-top: 29px;
  display: inline-block;
  width: 100%;

  .fill-orange-btn {
    padding: 10px;
    font-size: 14px;
  }
}
.reset_filter {
  font: 500 14px/22px 'Raleway';
  color: $colororange;
  display: inline-block;
  background: url(/images/icons/search_icons/reset.svg) no-repeat 0 0;
  text-indent: 22px;

  @include tablet {
    margin-top: 11px;
  }
}

/***filter css***/
.filter-panel {
  font: 500 14px/17px Lato;
  color: $colororange;
  display: inline-block;
  position: relative;

  &.orange_bg {
    .filter-list-name {
      background-color: $colororange;
      color: #fff;
      line-height: 21px;

      &.active {
        background: #ffffff;
        box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.2);
        color: $colororange;

        .shape {
          background: url(/images/icons/search_icons/shape.svg) no-repeat 0;
        }
      }
    }

    .shape {
      background: url(/images/icons/orange_shape.svg) no-repeat 0;
    }
  }

  .filter_recent_panel {
    position: absolute;
    top: 40px;
    right: 15px;
    background: #fff;
    min-width: 200px;
    z-index: 5;
    box-shadow: 0px 18px 14px rgba(0, 0, 0, 0.2);
    right: 0;

    ul {
      margin: 0;
      padding: 0;

      li {
        font: 14px/18px 'Lato';
        color: $darkgraycolor;
        list-style-type: none;
        padding: 10px 13px;
        cursor: pointer;

        &:hover {
          background: #fef2ec;
        }
      }
    }
  }

  .filter-list-name {
    display: inline-block;
    padding: 10px 12px;
    line-height: 14px;
    cursor: pointer;

    &.active {
      background: #ffffff;
      box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.2);
    }
  }

  .filter-list-wrap {
    margin-top: -8px;
    position: absolute;
    z-index: 3;
    max-width: 487px;

    @include tablet {
      width: 332px;
      left: -24px;
    }

    .product-wrap {
      background: #ffffff;
      padding: 15px;
      max-height: inherit;
      overflow: inherit;
      box-shadow: 0px 18px 14px rgba(0, 0, 0, 0.2);
      z-index: 1;
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: stretch;

      &.height_add {
        min-height: 180px;
      }

      .filter-list {
        margin: 0;
        padding: 0;
        width: 100%;

        li {
          padding: 6px;
          background: inherit;
          background: url(/images/icons/search_icons/right_icon.svg) no-repeat
            95% center;

          &.selected {
            background: rgba(242, 126, 68, 0.05)
              url(/images/icons/search_icons/right_icon.svg) no-repeat 95%
              center;
          }
        }

        &.overflow-lists {
          max-height: 200px;
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;

          .form-check-input[type='checkbox'] + label,
          label.btn input[type='checkbox'] + label {
            line-height: 18px;
          }
        }
      }

      .wid-50 {
        min-width: 190px;
        position: relative;

        @include tablet {
          min-width: inherit;
          width: 50%;
        }

        &.selected {
          width: 250px;
        }
      }

      .selected {
        background: rgba(242, 126, 68, 0.05);
        padding: 7px 11px;

        .filter-list {
          li {
            background: inherit !important;
          }
        }
      }
    }
  }
}

.filter-panel-mt-0 {
  @include phone {
    margin-top: 0 !important;
  }

  @include smallphone {
    margin-top: 0 !important;
  }
}

/***end filter css***/

/***arrow list***/
.arrow-list {
  background: #f7f7f7 url(/images/icons/Dropdownarrow.svg) no-repeat 97% center;
  cursor: pointer;
}

/*******/

/***tags****/
.tagPanel {
  display: inline-block;
  margin: 0 15px;
  vertical-align: middle;
  width: 86%;
  vertical-align: top;
  margin-top: 4px;

  .tags {
    font: normal 12px/12px 'Lato';
    color: #868e96;
    margin: 0 0 4px;
    padding: 6px 10px;
    border-radius: 6px;
    display: inline-block;
    background: #ffffff;
    border: 1px solid #f0f2f4;
    box-sizing: border-box;
    border-radius: 6px;
    margin-right: 8px;
  }
  .close_tag {
    background: url(/images/icons/search_icons/tagcross.svg) no-repeat 0 0;
    width: 11px;
    height: 11px;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    top: 2px;
  }
}

/***end***/

/****login text***/
.login_txt {
  font: 300 22px/26px 'Raleway';
  color: #000000;

  a {
    font-weight: 400;
  }
}

/**left arrow**/
.left_icon {
  display: inline-block;
  width: 34px;
  height: 26px;
  background: url(/images/icons/sign_icons/signup_leftarrow.svg) no-repeat 0
    center;
}
/****/

/****fill button disabled***/
.fill-orange-btn:disabled {
  background: rgba(242, 126, 68, 0.5);
}
/*******/
.outline-btn {
  &:disabled {
    opacity: 0.5;
  }
}

/******heading section*****/
.heading_section .left_icon {
  position: relative;
  top: 6px;
}

.arrow-list-autocomplete {
  background: #f7f7f7 url(/images/icons/Dropdownarrow.svg) no-repeat 97% center;
  cursor: pointer;
  width: 10px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 15px;
}

/******clear text**/
.clear_btn {
  margin-top: 39px;
  top: 14px;
  font: 500 14px/17px 'Lato';
  text-align: center;
  color: $colororange;
  margin-left: 32px;

  @include smallphone {
    float: right;
    margin-bottom: 10px;
  }

  &.disable_text {
    color: #b5bcc2;
  }
}

/**backup**/
.backup_contact {
  background: #f0f2f4;
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 190px;
  top: 0;

  .rep-icon {
    margin-right: 10px;
    float: left;
  }

  .backup_info {
    font: 14px/17px 'Lato';
    color: #697f94;
    width: 100%;
    padding: 0 25px;

    .backup_name {
      color: $darknavycolor;
    }

    .backup_name a {
      color: $darknavycolor !important;
    }
  }
}

.backup_rep_contact {
  font-size: 12px;
  white-space: nowrap;

  .rep-icon {
    width: 24px;
    height: 24px;
    background-size: contain;
    margin-right: 6px;
    position: relative;
    top: 5px;
  }

  .backup_rep_name {
    color: $darknavycolor;
    display: inline-block;
  }
}

.rep-icon {
  background: url(https://cdn.repsource.com/repsource-card-icon.svg) no-repeat 0
    center;
  width: 32px;
  height: 35px;
  display: inline-block;
  padding: 0;
  cursor: pointer;
}

/***provider search css for mobile***/
.row {
  &.search_reverse {
    @include desktop {
      flex-direction: column-reverse;
    }

    .search-right-panel {
      @include desktop {
        padding: 0;
        border-left: 0;
      }
    }
  }
}

#my-colleagues-container {
  .search-right-panel {
    padding: 0;
    border-left: 0;
  }

  div.search_bar {
    max-width: 86%;
  }
}

/***extra helper classes***/
.hidden {
  display: none;
}
.relative {
  position: relative;
}
.claim_btn {
  font: 600 14px/18px 'Raleway';
  background-color: #002f6c;
  color: #ffffff;
  padding: 8px 15px;
  border-radius: 50px;
  cursor: pointer;
}

.claim_btn_mobile {
  display: none;
  @include phone {
    display: block;
    margin-bottom: 10px;
    text-align: center;
  }

  @include smallphone {
    display: block;
    margin-bottom: 10px;
    text-align: center;
  }
}

.nrm {
  height: 50px;
  @include phone {
    margin-right: 0;
    width: 38%;
  }

  @include smallphone {
    margin-right: 0;
    width: 38%;
  }
}

.wrap-text {
  // @include phone {
  overflow-wrap: anywhere;
  white-space: normal;
  // }

  // @include smallphone {
  //   overflow-wrap: anywhere;
  //   white-space: normal;
  // }
}

.float-right {
  position: unset !important;
  @include phone {
    bottom: 25px;
    float: right;
  }

  @include smallphone {
    position: unset !important;
    bottom: 25px;
    float: right;
  }
}

.auto-height {
  height: auto;
}

.filter-list-mobile {
  @include phone {
    left: -24px !important;
  }

  @include smallphone {
    left: -24px !important;
  }
}

/***adding room to the bottom of all containers if on mobile***/
.container {
  @include phone {
    min-height: 400px;
    margin-bottom: 100px;
    clear: both;
  }

  @include smallphone {
    min-height: 400px;
    margin-bottom: 100px;
    clear: both;
  }
}

/**********end***********/
.dateTimePicker {
  width: 400px;
}

.dd_meeting_reason {
  // input {
  //   font-size: 14px !important;
  //   color: #757575 !important;
  // }
}

.placeholder-sm::placeholder {
  font-size: 14px !important;
  color: #757575 !important;
}
